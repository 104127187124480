<template>
  <div class="mb-1">
    <!-- advance search input -->
    <b-row align-h="end">
      <b-col
        md="4"
        class="d-flex justify-content-end"
      >
        <router-link :to="{ name: 'new-request'}">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            {{ $t('REQUEST.FILTRE.NOUVELLE_DEMANDE') }}
          </b-button>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <label>{{ $t('REQUEST.FILTRE.SOCIETE') }}</label>
          <v-select
            v-model="filter.societe"
            multiple
            label="societe"
            :placeholder="$t('REQUEST.FILTRE.SOCIETE')"
            :options="entitiesRequestList"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>{{ $t('REQUEST.FILTRE.STATUT') }}</label>
          <v-select
            v-model="filter.etat"
            multiple
            :placeholder="$t('REQUEST.FILTRE.STATUT')"
            :options="statusRequestList"
            :reduce="label => label.status"
            label="label"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>{{ $t('REQUEST.URGENCE') }}</label>
          <v-select
            v-model="filter.urgence"
            multiple
            :placeholder="$t('REQUEST.URGENCE')"
            :options="severityRequestList"
            :reduce="label => label.severity"
            label="label"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>{{ $t('REQUEST.FILTRE.DATE_DE_CREATION_DU') }}</label>
          <flat-pickr
            v-model="configStartDate.value"
            class="form-control"
            :config="configStartDate.config"
            @on-change="onChangeDate($event, 'start')"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>{{ $t('REQUEST.FILTRE.DATE_DE_CREATION_AU') }}</label>
          <flat-pickr
            v-model="configEndDate.value"
            class="form-control"
            :config="configEndDate.config"
            @on-change="onChangeDate($event, 'end')"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <label>{{ $t('REQUEST.FILTRE.RECHERCHE') }}</label>
          <b-form-input
            v-model="filter.recherche"
            type="text"
            class="d-inline-block"
            :placeholder="$t('REQUEST.FILTRE.RECHERCHE')"
            @input="advanceSearch"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row align-h="end">
      <b-col
        md="4"
        class="d-flex justify-content-end"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="onSearch()"
        >
          {{ $t('REQUEST.FILTRE.RECHERCHER') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import { French } from 'flatpickr/dist/l10n/fr'

export default {
  components: {
    BButton,
    vSelect,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    flatPickr,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    notificationSelected: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 10,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    droit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      entitiesRequestList: [],
      statusRequestList: [],
      severityRequestList: [],
      filter: {
        societe: [],
        etat: [],
        urgence: [],
        recherche: '',
        startDate: '',
        endDate: '',
      },
      configStartDate: {
        config: {
          locale: French,
          dateFormat: 'd/m/Y',
          minDate: null,
          maxDate: null,
        },
        value: null,
      },
      configEndDate: {
        config: {
          locale: French,
          dateFormat: 'd/m/Y',
          minDate: null,
          maxDate: null,
        },
        value: null,
      },
      dir: false,
    }
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  watch: {
    activeSociete() {
      this.resetFilter()
    },
    currentPage() {
      this.onSearch()
    },
    limit() {
      this.onSearch(1)
    },
    /**
     * Watcher qui permet de reset le champ "Statut" lors d'un clic sur
     * une autre icone de notification de la vue `NotificationHotline.vue`
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    notificationSelected() {
      if (this.notificationSelected) {
        this.resetFilter(true)
      }
    },
  },
  created() {
    // La pérénité des filtres est garantie par le localStorage.
    // Le localStorage contient 2 objets filtres :
    // - `rawFilter` sert à manipuler et remplir les composants Vue
    // - `formatedFilter` sert à être envoyé à la requête pour l'API back

    // Vérification de l'existence de l'objet `rawFilter` dans le localStorage
    if (localStorage.getItem('rawFilter') !== null) {
      // const tmpLocalStorageGet = JSON.parse(localStorage.getItem('rawFilter'))
      // const getActiveSocieteFromLocalStorage = JSON.parse(localStorage.getItem('activeSociete'))
      /* const newFilterWithSocieteActive = [{
        idSociete: getActiveSocieteFromLocalStorage.idSociete,
        societe: getActiveSocieteFromLocalStorage.societe,
      }]

      localStorage.setItem('rawFilter', JSON.stringify({ ...tmpLocalStorageGet, societe: newFilterWithSocieteActive })) */
      const rawFilterFromLocalStorage = JSON.parse(localStorage.getItem('rawFilter'))
      // Enregistrement du placeholder des `datepickers` en fonction de la date enregistrée dans `rawFilter`
      this.configStartDate.value = this.$luxon(rawFilterFromLocalStorage.startDate, 'dd/MM/yyyy')
      this.configEndDate.value = this.$luxon(rawFilterFromLocalStorage.endDate, 'dd/MM/yyyy')

      // this.filter prend la valeur de `rawFilter` du localStorage
      this.filter = rawFilterFromLocalStorage

      // On enregistre le 2ème object `formatedFilter` en formattant les valeurs de `rawFilter`
      localStorage.setItem('formatedFilter', JSON.stringify(this.formatParams(rawFilterFromLocalStorage)))
    } else {
      // Si il n'y a pas de `rawFilter` dans le localStorage :
      // on remplit le champ `Société` avec la valeur de la société active (en haut à droite)
      this.filter.societe.push({ idSociete: this.activeSociete.idSociete, societe: this.activeSociete.societe })
      // on set par défaut le champ `Statut` avec des valeurs prédéterminées dans le store
      this.filter.etat.push(...this.$store.getters['hotline/getStatusDefaultRequestList'])
      // on enregistre le nouvel objet `rawFilter` dans le localStorage
      localStorage.setItem('rawFilter', JSON.stringify(this.filter))
      // on enregistre le nouvel objet `formatedFilter` dans le localStorage
      localStorage.setItem('formatedFilter', JSON.stringify(this.formatParams(this.filter)))
    }

    // Remplissage des v-select
    const tmpStatusArray = this.$store.getters['hotline/getStatusRequestList']
    tmpStatusArray.forEach(element => {
      this.statusRequestList.push({ status: element, label: this.$i18n.t(`REQUEST.STATUT.${element}`) })
    })
    const tmpSeverityArray = this.$store.getters['hotline/getSeverityRequestList']
    tmpSeverityArray.forEach(element => {
      this.severityRequestList.push({ severity: element, label: this.$i18n.t(`REQUEST.URGENCES.${element}`) })
    })
    this.entitiesRequestList = this.$store.getters['societe/getSocieteList']

    const user = JSON.parse(localStorage.getItem('userData'))
    const droits = user.droits.filter(el => el.droit === this.droit && el.can === true && el.codename === localStorage.getItem('codename'))
    const societeForModule = []
    droits.map(el => societeForModule.push(el.societe))
    this.entitiesRequestList = this.entitiesRequestList.filter(el => societeForModule.includes(el.idSociete))

    // Ajout d'un watcher sur le filtre
    // Lorsque le filtre sera mis à jour, le watcher mettra à jour les objets
    // dans le localStorage
    this.$unwatch = this.$watch(
      () => this.filter,
      value => {
        localStorage.setItem('rawFilter', JSON.stringify(value))
        localStorage.setItem('formatedFilter', JSON.stringify(this.formatParams(value)))
      },
      { deep: true },
    )
  },
  beforeDestroy() {
    // Suppression du watcher avant destruction de la vue
    this.$unwatch()
  },
  methods: {
    /**
     * Méthode permettant de reset les filtres avant de relancer une recherche.
     * Cette méthode est appelée lorsque l'on change la société active ou
     * lorsque l'on clique sur une icone de notification (cloches en haut à droite)
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    resetFilter(fromNotification) {
      this.filter.societe.splice(0, this.filter.societe.length)
      this.filter.societe.push({ idSociete: this.activeSociete.idSociete, societe: this.activeSociete.societe })
      this.filter.urgence = []
      this.filter.recherche = ''
      this.filter.startDate = ''
      this.filter.endDate = ''
      this.configStartDate.value = ''
      this.configEndDate.value = ''
      if (fromNotification) {
        this.filter.etat.splice(0, this.filter.etat.length)
        this.filter.etat.push(this.notificationSelected)
      } else {
        this.filter.etat.splice(0, this.filter.etat.length)
        this.filter.etat.push(...this.$store.getters['hotline/getStatusDefaultRequestList'])
      }
      this.onSearch()
    },
    /**
     * Méthode permettant de formatter les valeurs reçues depuis l'objet
     * `rawFilter` afin de les renvoyer sous un autre format qui sera lisible
     * pour les requêtes API
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    formatParams(params) {
      const formatedParams = {
        contact: '',
        societe: params.societe.map(element => element.idSociete).join(','),
        etat: params.etat.join(','),
        urgence: params.urgence.join(','),
        page: 1,
        recherche: params.recherche,
        date_debut: params.startDate,
        date_fin: params.endDate,
      }
      return formatedParams
    },

    /**
     * Méthode qui est appelée par plusieurs autres fonctions et surtout par le bouton "Rechercher"
     * Elle permet de construire l'objet Paramètre qui sera passé à la fonction chargée de l'envoi de la requête
     * au back. Pour ce faire, la fonction emet un event catché par le composant parent.
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    onSearch() {
      const params = this.formatParams(this.filter)
      this.$emit('onSearchFromChild', { paramsFromFilter: params })
    },

    /**
     * Méthode appelée à chaque clic sur une date (sur les datespickers).
     * Elle permet de catcher l'event et de définir le maximum et le minimum
     * des deux datepickers.
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    onChangeDate(event, type) {
      if (type === 'start') {
        this.configEndDate.config.minDate = event[0] ? this.$luxon(event[0].toISOString(), 'dd/MM/yyyy') : ''
        this.filter.startDate = this.configEndDate.config.minDate.split('/').reverse().join('-')
      } else if (type === 'end') {
        this.configStartDate.config.maxDate = event[0] ? this.$luxon(event[0].toISOString(), 'dd/MM/yyyy') : ''
        this.filter.endDate = this.configStartDate.config.maxDate.split('/').reverse().join('-')
      }
    },

    /**
     * Méthode permettant de catcher la value du champ `Recherche`
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    advanceSearch(value) {
      this.filter.recherche = value
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
