<template>
  <b-row>
    <b-col>
      <b-card
        v-for="(item, index) in rows"
        :key="index"
      >
        <!-- Redirection au clic sur la carte -->
        <router-link
          :to="{ name: 'request-detail', params: { id: item.idHotline } }"
          class="routerDecoration"
        >
          <!-- Ligne 1 -->
          <b-row
            align-h="between"
            class="mb-1"
          >
            <b-col cols="5">
              <b-badge :variant="badgeVariantEtat(item.etat)">
                {{ $t(`REQUEST.STATUT.${item.etat}`) }}
              </b-badge>
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >
              <b-avatar
                size="2em"
                variant="danger"
              >
                <feather-icon
                  :icon="getPoleIcon(item.poleConcerne)"
                />
              </b-avatar>
            </b-col>
          </b-row>

          <!-- Ligne 2 -->
          <b-row class="mb-1">
            <b-col cols="11">
              <span class="boldSpan">{{ item.idHotline }}</span> - {{ item.hotline }}
            </b-col>
          </b-row>

          <!-- Ligne 3 -->
          <b-row align-h="between">
            <b-col
              cols="5"
              align-self="center"
            >
              <b-row
                no-gutters
                align-v="center"
                class="customRow"
              >
                <feather-icon icon="CalendarIcon" />
                <span>
                  {{ getFormattedDate(item.date) }}
                </span>
              </b-row>
            </b-col>
            <b-col
              cols="3"
              class="text-right"
            >
              <b-avatar
                :text="getFormattedName(item.idContact)"
                variant="light-success"
              />
            </b-col>
          </b-row>
        </router-link>
      </b-card>
    </b-col>
    <b-container>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItem"
          :per-page="limit"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          align="center"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-container>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BAvatar, BPagination, BContainer,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BContainer,
    BPagination,
    BAvatar,
    BBadge,
    BRow,
    BCol,
  },
  props: {
    resetPageFromParent: {
      type: Boolean,
      default: false,
    },
    ticketList: {
      type: Array,
      default: null,
    },
    totalItemFromParent: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      rows: [],
      totalItem: 0,
      currentPage: 1,
      limit: 5,
    }
  },
  computed: {
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  watch: {
    activeSociete() {
      this.$store.dispatch('hotline/getNotificationsHotline', { idSociete: this.activeSociete.idSociete })
    },
    ticketList() {
      this.updateRows()
    },
    currentPage() {
      this.onPageChange('fromPage')
    },
  },
  methods: {
    updateRows() {
      this.rows = this.ticketList
      this.totalItem = this.totalItemFromParent
    },
    onPageChange(from) {
      const params = {
        from: '',
        value: '',
      }
      params.from = from
      params.value = from === 'fromPage' ? this.currentPage : this.limit
      this.$emit('onSearchFromTable', params)
    },
    getFormattedDate(sourceDate) {
      return Intl.DateTimeFormat(new Date(sourceDate)).format()
    },
    getFormattedName(contact) {
      // Regex pour supprimer les contenu entre parenthèses dans le prénom
      const regex = /\(.*\) /
      const fullName = `${contact.prenom} ${contact.nom}`
      const formattedName = fullName.replace(regex, '')
      return formattedName.split(' ').map(word => word[0].toUpperCase()).join('')
    },
    getPoleIcon(pole) {
      switch (pole) {
        case 'dev':
          return 'CodeIcon'
        case 'system':
          return 'ServerIcon'
        case 'telecom':
          return 'PhoneCallIcon'
        default:
          return ''
      }
    },
    /**
     * Méthode qui permet de définir le style du badge pour le champ `etat`
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    badgeVariantEtat(propsField) {
      switch (propsField) {
        case 'fixing':
          return 'light-success'
        case 'wait':
          return 'light-warning'
        case 'free':
          return 'light-danger'
        case 'done':
          return 'light-primary'
        case 'payee':
          return 'light-primary'
        case 'annulee':
          return 'light-secondary'
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.customRow {
    gap: 5px;
}
.routerDecoration {
  text-decoration: none;
  color: inherit;
}
.boldSpan {
  font-weight: bold;
}
</style>
