<template>
  <b-card>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :sort-options="{
        enabled: false,
      }"
    >
      <div
        id="emptySlot"
        slot="emptystate"
      >
        {{ $t('REQUEST.NO_DATA') }}
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.field == 'detail'"
          id="detailIcon"
        >
          <router-link :to="{ name: 'request-detail', params: { id: props.row.idHotline } }">
            <feather-icon
              icon="SearchIcon"
            />
          </router-link>
        </span>
        <span v-else-if="props.column.field === 'urgence'">
          <b-badge :variant="badgeVariantUrgence(props.row.urgence)">
            {{ $t(`REQUEST.URGENCES.${props.row.urgence}`) }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'etat'">
          <b-badge :variant="badgeVariantEtat(props.row.etat)">
            {{ $t(`REQUEST.STATUT.${props.row.etat}`) }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'poleConcerne'">
          {{ $t(`REQUEST.POLES.${props.row.poleConcerne}`) }}
        </span>
        <span v-else-if="props.column.field === 'contact'">
          {{ formatedNameContact(props.row) }}
          <br>
          <span class="font-small-3"> {{ props.row.idSociete.societe }}</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>

    <!-- pagination -->
    <div class="mt-1">
      <b-row>
        <b-col cols="6">
          <div class="d-flex align-items-center">
            <span class="text-nowrap"> {{ $t("PAGINATION.DISPLAY") }} </span>
            <v-select
              v-model="limit"
              label="limit"
              :clearable="false"
              :options="[5,10,25,50,100]"
              class="ml-1"
            />
          </div>
        </b-col>
        <b-col cols="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItem"
            :per-page="limit"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="right"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import vSelect from 'vue-select'
import {
  BBadge, BPagination, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BCard,
    BBadge,
    BPagination,
    vSelect,
    VueGoodTable,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  props: {
    resetPageFromParent: {
      type: Boolean,
      default: false,
    },
    ticketList: {
      type: Array,
      default: null,
    },
    totalItemFromParent: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      totalItem: 0,
      currentPage: 1,
      limit: 10,
      loading: false,
      dir: false,
      columns: [
        { field: 'detail' },
        { label: this.$i18n.t('REQUEST.NUMERO'), field: 'idHotline' },
        { label: this.$i18n.t('REQUEST.HOTLINE'), field: 'hotline' },
        {
          label: this.$i18n.t('REQUEST.DATE_DE_CREATION'),
          field: 'date',
          type: 'date',
          formatFn: this.formatDate,
        },
        { label: this.$i18n.t('REQUEST.URGENCE'), field: 'urgence' },
        { label: this.$i18n.t('REQUEST.POLE'), field: 'poleConcerne' },
        { label: this.$i18n.t('REQUEST.FILTRE.STATUT'), field: 'etat' },
        { label: this.$i18n.t('REQUEST.CONTACT'), field: 'contact' },
      ],
      rows: [],
    }
  },
  computed: {
    direction() {
      if (this.$store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  watch: {
    currentPage() {
      this.onPageChange('fromPage')
    },
    limit() {
      this.onPageChange('fromLimit')
    },
    resetPageFromParent() {
      this.currentPage = 1
    },
    ticketList() {
      this.updateRows()
    },
  },
  methods: {
    onPageChange(from) {
      const params = {
        from: '',
        value: '',
      }
      params.from = from
      params.value = from === 'fromPage' ? this.currentPage : this.limit
      this.$emit('onSearchFromTable', params)
    },
    updateRows() {
      this.$store.dispatch('hotline/getNotificationsHotline', { idSociete: this.activeSociete.idSociete })
      this.rows = this.ticketList
      this.totalItem = this.totalItemFromParent
    },
    /**
     * Méthode qui permet de définir le style du badge pour le champ `etat`
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    badgeVariantEtat(propsField) {
      switch (propsField) {
        case 'fixing':
          return 'light-success'
        case 'wait':
          return 'light-warning'
        case 'free':
          return 'light-danger'
        case 'done':
          return 'light-primary'
        case 'payee':
          return 'light-primary'
        case 'annulee':
          return 'light-secondary'
        default:
          return ''
      }
    },

    /**
     * Méthode qui permet de définir le style du badge pour le champ `urgence`
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    badgeVariantUrgence(propsField) {
      switch (propsField) {
        case 'bloquant':
          return 'light-danger'
        case 'genant':
          return 'light-warning'
        case 'detail':
          return 'light-info'
        default:
          return ''
      }
    },

    /**
     * Méthode pour formater le prénom et le nom du contact avant de l'enregistrer dans les lignes du tableau
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    formatedNameContact(obj) {
      let fullName = obj.idContact.nom
      if (obj.idContact.prenom != null) {
        fullName = `${obj.idContact.prenom} ${obj.idContact.nom}`
      }
      return fullName
    },

    /**
     * Méthode permettant de formater la date à afficher dans le tableau
     * @author Francisco Fernandez <ffernandez@absystech.fr>
     */
    formatDate(date) {
      return this.$luxon(date, 'dd/MM/yyyy')
    },
  },
}
</script>

<style lang="scss">
#detailIcon {
  cursor: pointer
}

#emptySlot {
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
